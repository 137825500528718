<template>
  <div
    class="text-center m-2"
    :active="active"
  >
    <!-- <b-avatar
      class="mb-1"
      :variant="`light-${color}`"
      size="45"
    >
      <feather-icon
        size="21"
        :icon="icon"
      />
    </b-avatar> -->
    <div>
      <!-- <feather-icon
        :icon="resolveInvoiceStatusVariantAndIcon(data.item.status).icon"
      /> -->
      <h4 class="mb-25 font-weight-bolder">
        <b-badge
          :variant="`light-${color}`"
          style="background-color: rgb(20 0 0 / 12%);"
        >
          {{ statistic }}
        </b-badge>
      </h4>
      <span>{{ statisticTitle }}</span>
    </div>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'

export default {
  components: {
    // BCard,
    BBadge,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
