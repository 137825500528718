<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="12"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column">
            <div class="mb-1">
              <h4>
                Станция: #{{ stationData.id }}
              </h4>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <b-list-group
          v-for="(item, key) in stationData"
          :key="key"
          flush
        >
          <b-list-group-item
            v-if="['location','location_id','workable_status','serial_number','name'].includes(key)"
            class="d-flex justify-content-between align-items-center"
          >
            <span class="text-capitalize">{{ key }}:</span>
            <b-badge
              v-if="item!=undefined"
              variant="light-primary"
              pill
              class="badge-round text-capitalize badge-glow"
            >
              {{ item }}
            </b-badge>
          </b-list-group-item>
        </b-list-group>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <b-list-group
          v-for="(item, key) in stationData"
          :key="key"
          flush
        >
          <b-list-group-item
            v-if="['firmware','hardware','createdAt','updatedAt'].includes(key)"
            class="d-flex justify-content-between align-items-center"
          >
            <span class="text-capitalize">{{ key }}:</span>
            <b-badge
              v-if="item!=undefined"
              variant="light-primary"
              pill
              class="badge-round text-capitalize badge-glow"
            >
              {{ item }}
            </b-badge>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
// import useUsersList from '../station-list/useStationList'

export default {
  components: {
    BCard, BRow, BCol, BListGroup, BListGroupItem, BBadge,
  },
  props: {
    stationData: {
      type: Object,
      required: true,
    },
    roleData: {
      type: Array,
      required: true,
    },
  },
  setup() {
    // const { resolveRoleVariant } = useUsersList()
    return {
      avatarText,
      // resolveRoleVariant,
    }
  },
}
</script>

<style>

</style>
