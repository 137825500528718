import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCars(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Cars/', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCar(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Cars/', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCar(ctx, carData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Cars/', carData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCar(ctx, { id, carData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/updateRow/Cars/${id}`, carData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteCar(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/deleteRow/Cars/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
