<template>
  <b-card>
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex justify-content-start">
            <h4>
              Батареи:
            </h4>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              @click="rentDevice(carData.serial_number)"
            >
              Демо аренда
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      responsive
      :items="batteryData"
      :fields="fields"
      class="mb-0"
      :busy="!batteryData"
      show-empty
      empty-text="No matching records found"
      primary-key="id"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner
            class="align-middle mr-1"
          />
          <strong>Загрузка...</strong>
        </div>
      </template>
      <template #cell(id)="data">
        <b-link
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
      </template>
      <template #cell(status)="data">
        <div class="text-nowrap">
          <b-avatar
            :id="`battery-row-${data.item.id}`"
            size="32"
            :variant="`light-${resolveBatteryStatusVariantAndIcon(data.item.status).variant}`"
          >
            <feather-icon
              :icon="resolveBatteryStatusVariantAndIcon(data.item.status).icon"
            />
          </b-avatar>
          <span :class="`text-${resolveBatteryStatusVariantAndIcon(data.item.status).variant} align-text-top text-capitalize ml-1`">{{ data.item.status }}</span>
        </div>
        <b-tooltip
          :target="`battery-row-${data.item.id}`"
          placement="bottom"
        >
          <p class="mb-0">
            {{ data.item.status }}
          </p>
        </b-tooltip>
      </template>
      <template #cell(workable_status)="data">
        <template v-if="data.value === 'Исправен'">
          <b-badge
            pill
            variant="light-success"
          >
            {{ data.value }}
          </b-badge>
        </template>
        <span v-else>
          Нет данных
        </span>
      </template>
      <template #cell(energy_left)="data">
        <span
          :class="`text-${resolveEnergyVariant(data.value)}`"
        >
          {{ data.value }}
        </span>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Total {{ totalBatteries }} entries</span>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BSpinner,
  BBadge,
  BAvatar,
  BLink,
  BTooltip,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import carStoreModule from '../carStoreModule'
// import useUsersList from '../station-list/useStationList'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    BBadge,
    BAvatar,
    BLink,
    BTooltip,
    BRow,
    BCol,
    BButton,
  },
  props: {
    carData: {
      type: Object,
      required: true,
    },
    batteryData: {
      type: Array,
      required: true,
    },
    totalBatteries: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'id', label: '#', sortable: true },
        { key: 'name', label: 'Название', sortable: true },
        { key: 'serial_number', label: 'Серийный номер', sortable: true },
        { key: 'energy_left', label: 'Заряженность', sortable: true },
        { key: 'status', label: 'Статус', sortable: true },
        { key: 'workable_status', label: 'Работаспособность', sortable: true },
        { key: 'createdAt', label: 'Дата добавления', sortable: true },
        { key: 'updatedAt', label: 'Дата обновления', sortable: true },
        { key: 'actions', label: 'Действия' },
      ],
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const resolveBatteryStatusVariantAndIcon = status => {
      if (status === 'WAITING') return { variant: 'warning', icon: 'ClockIcon' }
      if (status === 'RENTED') return { variant: 'success', icon: 'CheckCircleIcon' }
      return { variant: 'secondary', icon: 'XIcon' }
    }
    const CAR_APP_STORE_MODULE_NAME = 'app-car'

    // Register module
    if (!store.hasModule(CAR_APP_STORE_MODULE_NAME)) store.registerModule(CAR_APP_STORE_MODULE_NAME, carStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CAR_APP_STORE_MODULE_NAME)
    })
    const rentDevice = serial => {
      // set voice level of station
      store.dispatch('app-car/rentDevice', {
        boxId: serial,
      })
        .then(resp => {
          console.log(resp)
          if (resp.data.status === 'fail') {
            throw new Error('Status fail')
          }
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success! Please take battery from station',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error renting battery',
              icon: 'AlertTriangleIcon',
              variant: 'warning',
            },
          })
        })
        .finally(() => {
          emit('refetch-data')
        })
    }
    const resolveEnergyVariant = value => {
      if (value > 2) {
        return 'success'
      }
      if (value <= 2) {
        return 'warning'
      }
      return 'error'
    }
    return {
      avatarText,
      resolveBatteryStatusVariantAndIcon,
      rentDevice,
      resolveEnergyVariant,
      // resolveRoleVariant,
    }
  },
}
</script>

<style>

</style>
